<!-- <ion-row class="item-row">
  <ion-col size="12" size-md="3.5">
      <img src="assets/image/plan-prerent.png" alt="">
  </ion-col>
  <ion-col size="12" offset-lg="0.5" size-md="8" class="ion-align-self-center">
    <ion-row>
      <ion-col class="ion-no-padding" size="12" size-md="7">
        <h2 class="ion-no-margin">全新車預租計劃</h2>
        <p>需要更多時間考慮購買全新車？</p>
      </ion-col>
      <ion-col class="ion-no-padding ion-text-right" size="12" size-md="5" >
        <ion-button  href="https://prerent.wasphk-rental.com/" target="_blank" class="default-btn" shape="round">
          了解更多
        </ion-button>
      </ion-col>
      <ion-col class="ion-no-padding" size="12">
        <ol>
          <li>今天，你就是全新車主！但這其實有點不同，我們為你提供全新車輛的租賃方案，而你不需要一次性支付全額車價。我們提供清晰透明的定價，在親身駕駛過後，您可以隨時隨地決定購入車輛！只需在應用程式上按照指示確認交易，輕鬆完成購車手續！</li>
          <li>使用預租計劃 ，GoSwap會為你提供一輛全新的電動車，你可以將六個月的租期視作試用期，親自駕駛後感到稱心滿意，可於GoSwap APP直接入手車輛；萬一車輛未合乎心中所想？不用擔心，租期結束後你可以選擇交還車輛，再重新尋覓「你」想車款！</li>
        </ol>
      </ion-col>
    </ion-row>
  </ion-col>
</ion-row> -->

<ion-row class="item-row">
  <ion-col size="12" size-md="3.5">
    <img src="assets/image/plan-rwp.png" alt="">
  </ion-col>
  <ion-col size="12" offset-lg="0.5" size-md="8" class="ion-align-self-center">
    <ion-row>
      <ion-col class="ion-no-padding" size="12" size-md="7">
        <h2 class="ion-no-margin">{{'common.rent_with_park_plan' | translate}}</h2>
        <p>{{'plan_item.slogan' | translate}}</p>
      </ion-col>
      <ion-col class="ion-no-padding ion-text-right" size="12" size-md="5">
        <ion-button (click)="navigationService.navigate('root', 'rent-with-park')" class="default-btn" shape="round">
          {{'common.know_more' | translate}}
        </ion-button>
      </ion-col>
      <ion-col class="ion-no-padding" size="12">
        <p>{{'rent_with_park.slogan' | translate}}</p>
      </ion-col>
    </ion-row>
  </ion-col>
</ion-row>

<ion-row class="item-row">
  <ion-col size="12" size-md="3.5">
    <img src="assets/image/plan-rtb.png" alt="">
  </ion-col>
  <ion-col size="12" offset-lg="0.5" size-md="8" class="ion-align-self-center">
    <ion-row>
      <ion-col class="ion-no-padding" size="12" size-md="7">

        <h2 class="ion-no-margin">{{'common.rent_to_buy_plan' | translate}}</h2>
        <p [innerHTML]="'home.slogan4' | translate"></p>
      </ion-col>
      <ion-col class="ion-no-padding ion-text-right" size="12" size-md="5">
        <ion-button class="default-btn" shape="round" (click)="navigationService.navigate('root', 'rent-to-buy')">
          {{'common.know_more' | translate}}
        </ion-button>
      </ion-col>
      <ion-col class="ion-no-padding" size="12">
        <p>{{'rent_to_buy.slogan2' | translate}}</p>
      </ion-col>

    </ion-row>
  </ion-col>
</ion-row>

<ion-row class="item-row">
  <ion-col size="12" size-md="3.5">
    <img src="assets/image/plan-long-term-rental.png" alt="">
  </ion-col>
  <ion-col size="12" offset-lg="0.5" size-md="8" class="ion-align-self-center">
    <ion-row>
      <ion-col class="ion-no-padding" size="12" size-md="7">

        <h2 class="ion-no-margin">{{'common.long_term_rental' | translate}}</h2>
        <p [innerHTML]="'long_term_rental.slogan' | translate"></p>
      </ion-col>
      <ion-col class="ion-no-padding ion-text-right" size="12" size-md="5">
        <ion-button class="default-btn" shape="round" (click)="navigationService.navigate('root', 'long-term-rental')">
          {{'common.know_more' | translate}}
        </ion-button>
      </ion-col>
      <ion-col class="ion-no-padding" size="12">
        <p>{{'long_term_rental.coop' | translate}}</p>
        <img src="assets/image/goswap_inchcape.png" alt="">
        <p>{{'long_term_rental.slogan2' | translate}}</p>
      </ion-col>

    </ion-row>
  </ion-col>
</ion-row>

<!-- <ion-row class="item-row">
  <ion-col size="12" size-md="3.5">
      <img src="assets/image/plan-6plus1.png" alt="">
  </ion-col>
  <ion-col size="12" offset-lg="0.5" size-md="8" class="ion-align-self-center">
    <ion-row>
      <ion-col class="ion-no-padding" size="12" size-md="7">
        <h2 class="ion-no-margin">6+1計劃</h2>
        <p>準備好租用一段長時間嗎？我們再為你免費延長一個月</p>
      </ion-col>
      <ion-col class="ion-no-padding ion-text-right" size="12" size-md="5" >
        <ion-button  class="default-btn" shape="round" (click)="navigationService.navigate('root', 'six-plus-one')">
          了解更多
        </ion-button>
      </ion-col>
      <ion-col class="ion-no-padding" size="12">
        <p>在 GoSwap 的應用程式上，你可以根據自己的需求選擇不同的車款和租期。而如果你正在考慮長期租用車輛，6+1 計劃可以為你豁免第 7 個月全數租金，在行程的最後階段創造更好的體驗。</p>
      </ion-col>
    </ion-row>
  </ion-col>
</ion-row> -->