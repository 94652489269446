import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EnvPipe } from '../pipes/env.pipe';
import { HeaderComponent } from '../components/header/header.component';
import { FooterComponent } from '../components/footer/footer.component';
import { IonicModule } from '@ionic/angular';
import { CountUpDirective } from '../directives/count-up.directive';
import { InfluenceItemComponent } from '../components/influence-item/influence-item.component';
import { PlanItemComponent } from '../components/plan-item/plan-item.component';
import { ParkingCardComponent } from '../components/parking-card/parking-card.component';
import { MaterialModule } from '../material/material.module';
import { AnimateDirective } from '../directives/animate.directive';
import { CarItemComponent } from '../components/car-item/car-item.component';
import { FaqSectionComponent } from '../components/faq-section/faq-section.component';
import { MobileDownloadAppComponent } from '../components/mobile-download-app/mobile-download-app.component';
import { TranslateModule } from '@ngx-translate/core';
import { SafePipe } from '../pipes/safe.pipe';
import { StartEndPanelComponent } from '../components/start-end-panel/start-end-panel.component';
import { TermsComponent } from '../components/terms/terms.component';
import { PrivacyComponent } from '../components/privacy/privacy.component';
import { GalleryComponent } from '../components/gallery/gallery.component';
import { FactoryCardComponent } from '../components/factory-card/factory-card.component';

@NgModule({
    declarations: [
        EnvPipe,
        SafePipe,
        HeaderComponent,
        FooterComponent,
        CountUpDirective,
        InfluenceItemComponent,
        PlanItemComponent,
        ParkingCardComponent,
        AnimateDirective,
        CarItemComponent,
        FaqSectionComponent,
        MobileDownloadAppComponent,
        StartEndPanelComponent,
        TermsComponent,
        PrivacyComponent,
        GalleryComponent,
        FactoryCardComponent
    ],
    imports: [
        CommonModule,
        IonicModule.forRoot({rippleEffect: false}),
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule
    ],
    exports: [
        CommonModule,
        TranslateModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        EnvPipe,
        SafePipe,
        HeaderComponent,
        FooterComponent,
        CountUpDirective,
        InfluenceItemComponent,
        PlanItemComponent,
        ParkingCardComponent,
        AnimateDirective,
        CarItemComponent,
        FaqSectionComponent,
        MobileDownloadAppComponent,
        StartEndPanelComponent,
        TermsComponent,
        PrivacyComponent,
        GalleryComponent,
        FactoryCardComponent
    ],
    schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
