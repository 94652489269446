import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-influence-item',
  templateUrl: './influence-item.component.html',
  styleUrls: ['./influence-item.component.scss'],
})
export class InfluenceItemComponent  implements OnInit {

  constructor(
    public navigationService: NavigationService
  ) { }

  ngOnInit() {}

}
