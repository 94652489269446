<div class="car-item-container"
  [ngClass]="{'prerent-active': (dataService?.selected_prerent_car_data != null && dataService?.selected_prerent_car_data.id == car_data?.id), 'prerent': navigationService.current_router_url.includes('rent-to-buy')}"
  (click)="selectCar(car_data.id)">
  <ng-container *ngIf="!rtb_desktop_style">
    <ion-img [src]="('media_url' | env) + car_data.cover_img_url_list[0]"></ion-img>
    <div class="ion-text-left mobile-hide">
      <p class="ion-no-margin brand">{{car_data?.brand}}</p>
      <h3 class="model">{{car_data?.model}}</h3>
      <h5 class="price" *ngIf="!navigationService.current_router_url.includes('rent-to-buy')">
        {{'common.as_low_as' | translate}}
        {{car_data?.offer_plan_list[car_data.offer_plan_list.length-1]?.price_per_day |
        currency:'HKD':'symbol-narrow':'1.0-0'}} {{'common.up' | translate}}
        <span>/{{'common.daily_rent' | translate}}</span>
      </h5>
    </div>
    <div class="ion-text-left desktop-hide">
      <p class="ion-no-margin brand">{{car_data?.brand}}</p>
      <p class="model">{{car_data?.model}}</p>
      <p class="price" *ngIf="!navigationService.current_router_url.includes('rent-to-buy')">
        {{'common.as_low_as' | translate}}
        {{car_data?.offer_plan_list[car_data.offer_plan_list.length-1]?.price_per_day |
        currency:'HKD':'symbol-narrow':'1.0-0'}} {{'common.up' | translate}}
        <span>/{{'common.daily_rent' | translate}}</span>
        </p>
    </div>

    <div class="year-seat" *ngIf="navigationService.current_router_url.includes('rent-to-buy')">
      <div class="year">{{car_data?.year}}{{'common.year' | translate}}</div>
      <ion-label>{{car_data?.seat}}{{'common.seats' | translate}}</ion-label>
    </div>
  </ng-container>
  <ng-container *ngIf="rtb_desktop_style">
    <ion-row>
      <ion-col size="4" class="ion-align-self-center">
        <ion-img [src]="('media_url' | env) + car_data.cover_img_url_list[0]"></ion-img>
      </ion-col>
      <ion-col size="4" class="ion-align-self-center">
        <h3 class="model">{{car_data?.brand}} {{car_data?.model}}</h3>
        <div class="year">{{car_data?.year}}年</div>
      </ion-col>
      <ion-col size="3" class="ion-align-self-center">
        <p class="ion-no-margin ion-text-right">{{'common.monthly_price' | translate}}HKD</p>
        <h2 class="ion-no-margin ion-text-right">
          {{car_data?.offer_plan_list[car_data.offer_plan_list.length-1]?.price_per_day*30 |
          currency:'HKD':'symbol-narrow':'1.0-0'}}</h2>
      </ion-col>
      <ion-col size="1" class="ion-align-self-center">
        <ion-icon class="prerent-forward-icon" name="chevron-forward"></ion-icon>
      </ion-col>
    </ion-row>
  </ng-container>
</div>