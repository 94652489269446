import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-mobile-download-app',
  templateUrl: './mobile-download-app.component.html',
  styleUrls: ['./mobile-download-app.component.scss'],
})
export class MobileDownloadAppComponent  implements OnInit {

  constructor(
    public commonService: CommonService
  ) { }

  ngOnInit() {}

}
