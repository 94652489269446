<!-- <ion-header class="desktop-hide">
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="modalController.dismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-row>
    <ion-col size="0" size-md="1" class="ion-align-self-center ion-text-right mobile-hide">
      <ion-icon (click)="slidePrev()" name="chevron-back-outline"></ion-icon>

    </ion-col>
    <ion-col size="12" size-md="10" class="ion-align-self-center ion-text-center">
      <swiper-container #swiper (init)="swiperReady()" (slidechange)="onSlideChange()" [pagination]="true"
        [autoHeight]="false" [initialSlide]="current_index">
        <swiper-slide *ngFor="let item of img_url_list">
          <img [src]="('media_url' | env)+item" alt="">
        </swiper-slide>
      </swiper-container>
    </ion-col>
    <ion-col size="0" size-md="1" class="ion-align-self-center ion-text-left mobile-hide">
      <ion-icon (click)="slideNext()" name="chevron-forward-outline"></ion-icon>
    </ion-col>
  </ion-row>


</ion-content> -->

<ion-header class="desktop-hide">
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="modalController.dismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<swiper-container #swiper (init)="swiperReady()" (slidechange)="onSlideChange()" [pagination]="true"
  [autoHeight]="false" [initialSlide]="current_index">
  <swiper-slide *ngFor="let item of img_url_list">
    <img [src]="('media_url' | env)+item" alt="">
  </swiper-slide>
</swiper-container>