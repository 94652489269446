<div class="ion-padding">
  <h5>備註 Remark:</h5>
  <ol>
    <li>
      租用人在租車內觸犯交通規例所引致之法律責任、損失、賠償等，一概由租用人承擔。若我們收到罰款信，經由我們處理，我們會收取行政費每張港幣150元行政費用。此外，租用人依然需要為罰款負責。出租車輛因交通意外導致車身損毀，租用人必須支付每宗交通意外所涉及之保險自負額，若因租用人違反出租車輛合約規定或虛報資料而導致保險公司
      拒絕賠償，租用人須負責本公司之全部損失。
      <br>
      The HIRER shall bear and pay all penalties for traffic committed during the rental period and shall
      indemnify the COMPANY in respect thereof. If the COMPANY shall be demanded by the relevant authority to pay
      any penalty for an offence committed during the rental period, The COMPANY will charge the HIRER, in
      addition to the penalty, HK$150 for handling each such payment. When a HIRER, unfortunately, encounters a
      traffic accident arising from a third party(ies) and causing damage to the vehicle, the HIRER must be
      responsible for the deductible of (HK$20,000). Insurance claim failure caused by HIRER's breach of the
      rental agreement or false reporting will result in HIRER being personally liable for the loss incurred by
      the COMPANY.
    </li>
    <li>
      租車期內，因租用人及或第三者之疏忽而導致車輛嚴重損毀，需要入廠維修，本公司可視乎車輛供應安排另一車輛予租用人，並有權向租用人收取額外租金及有關之費用。
      <br>
      The COMPANY reserves the right to charge the HIRER an additional charge for a replacement Vehicle (Based on
      availability), if the Vehicle requires extensive repair due to accident damage.
    </li>

    <li>
      租用人必須保持車輛內外清潔及處於良好和適當的狀態，否則本公司有權收取合理之清潔或維修費用。
      <br>
      The HIRER shall keep the Vehicle clean and in good and proper condition and shall bear the cost of repair of
      any damage to the Vehicle resulting from negligent or improper use of the Vehicle by the HIRER or any person
      permitted by the HIRER to use the Vehicle.
    </li>
    <li>
      租車費用包括合法汽車租賃保險費（水災、颱風、暴動和罷工之風險除外）和保養費。
      <br>
      The rental fee includes a Government license fee, mandatory insurance (flood, typhoon, riot and strike risks
      are excepted) and maintenance. The fee includes insurance coverage against (a) general liability for
      HK$100,000,000 in respect of death or bodily injury to the third party; (b) third-party property damage
      subject to a maximum of HK$1,000,000.
    </li>

    <li>
      如您所預訂的車輛，在無法避免情況下損壞，引致未能如期向您提供車輛租用。本公司有權取消您的訂單或安排更換所租用的車款。如有上述之情況，本公司會儘快告知閣下。而我們不會另外收取費用;
      如因此需要取消訂單，租金及按金我們亦會以全數退還。
      <br>
      If the vehicle you booked is damaged under unavoidable circumstances, it may not be possible to provide you
      with a rental vehicle as scheduled. The COMPANY would cancel your order or arrange for another car for
      replacement. In case of the above situation, the COMPANY will inform you as soon as possible. And we will
      not charge any additional fees; if it is necessary to cancel the order, we will refund the rent and deposit
      in full.
    </li>

    <li>
      按金可透過應用程式申請退還，一般處理時間約為30天。
      <br>
      The deposit can be refunded through the APP, and the refund period is about 30 days after the application.
    </li>

    <li>
      有關超時租金及其他適用收費，請向本公司查詢。
      <br>
      For the extra hours and other optional charges, please contact us.
    </li>
    <li>
      本公司提供上門交付車輛服務，而有關費用請在應用程式中查看。
      <br>
      The COMPANY provides vehicle collection and delivery services. For the related amount please check the APP.
    </li>
    <li>
      租車費用不包括汽油，租用人在還車前必須把油缸填滿汽油。如本公司代入汽油，則按本公司訂單收費。
      <br>
      Gasoline is not included in the rental fee. The HIRER is responsible for refilling gasoline to total
      capacity on return. Refilling gasoline for HIRER will be charged at the rates of the gas station plus a
      handling charge of HK$200.
    </li>
    <li>
      當香港正受惡劣天氣影響時，租車服務安排︰當（黃色或紅色暴雨）或（1號或3號熱帶氣旋）警告信號生效時，租車服務會如常進行。當黑色暴雨警告、8號或以上的熱帶氣旋信號正在或即將懸掛，租車服務將會取消，租用日期亦會順延至下一個工作天。當黑色暴雨警告、8號或以上的颱風信號被解除後，本公司會陸續提供有限度服務。
      <br>
      Vehicle rental service will be operated as usual when the Rainstorm Warning Signal (Yellow/Red) or Tropical
      Cyclone Warning Signal (No.1/No.3) is issued. When a Black Rainstorm Warning Signal or Tropical Cyclone
      Warning Signal No. 8 or above is issued or about to be hoisted, the vehicle rental service will be
      cancelled, and the rental date will be rescheduled to the next working day. When a Black Rainstorm Warning
      Signal or Tropical Cyclone Warning Signal No. 8 or above is lifted, we will resume providing limited
      services.
    </li>
  </ol>
  <h5>條款及細則:</h5>
  <ol>
    <li>
      預約服務請於手機應用程式「黃蜂租車」上進行。
      <br>
      Reservation must be made at the mobile application ”WASP HK”
    </li>
    <li>
      訂單預訂後不設取消，若租用人需要就訂單日期作出更改，請於租賃開始前3個工作天內聯絡職員安排，否則，我們會收取租金的20%作行政費。
      <br>
      Cancellation is not allowed after the order has been made. If necessary to reschedule, please contact our
      staff within 3 working days before the rental start day. Otherwise, we will charge 20% of the rental fee as
      an administrative fee.
    </li>
    <li>
      駕駛者年齡必須為25歲或以上，並持有效駕駛執照達2年以上。辦理取車手續時須出示有效身分證文件及駕駛執照。
      <br>
      Drivers must be 25 years of age, or above, and has a valid Hong Kong Driving License for more than two
      years; Driver will need to present valid documents and ID before each transaction.
    </li>
    <li>
      駕駛者必需為租用人本人。除已登記之駕駛者有權駕駛出租車外，任何未有登記之駕車者因交通意外所引致之法律責任，一概由租用人承擔。
      <br>
      The drivers should be the HIRER. Apart from nominated drivers, any other person driving the Vehicle whether
      resulting in a traffic accident or not all loss and consequential damages are the responsibility of the
      HIRER.
    </li>
    <li>
      如租用人為公司名義登記，除了提交有效的商業登記證外，亦需提交一名年滿25歲、持駕駛執照兩年或以上的駕駛者作記名司機。
      <br>
      If the HIRER use the Company name registered, he is not only handing in the effective Business registration
      but also submitting a driver with a driver's license for 2 years or above who is over 25 years old as the
      named driver.
    </li>
    <li>
      租用人接受出租車輛前，應與本公司職員一同檢查車輛，若駛離車場後發覺車輛不妥，必須在一小時內返回或通知本公司以便檢查，否則有之責任將由租用人承擔。
      <br>
      The HIRER is requested to inspect the Vehicle with our staff before accepting the Vehicle. Any
      irregularities after being spotted should immediately make noticed and ONE hour grace period is the COMPANY
      policy for passing on the liability towards the HIRER.
    </li>
    <li>
      租用人若提早中斷合約，不論任何情況，有關之按金 / 租金，全部或部份，除非個別通知，否則將不會退回租用人。
      <br>
      No refund (Rental fee & Deposit), part or full, will be paid if the HIRER earlier terminate the contract
      under any circumstances.
    </li>
    <li>
      租用人必須預繳租金及支付按金，該按金只作保證金用途，不能當作租車之全部或部份租金，亦不得以按金作任何賠償（如：違例告票罰款、隧道費用、額外維修費、拖車費、清潔費、行政費等）。租用人如有違反租車合約內之任何條款，本公司有權將全部或部分之按金扣起，用作支付本公司之損失。
      <br>
      The HIRER must pay the rental as well as a deposit of suck sum specified in this Agreement in advance. The
      deposit can only be used as security for the due performance of the terms and conditions and cannot be used
      for any portion of the rental fee. Besides, the deposit cannot be used for any portion of the compensation
      (E.g. penalty ticket, toll fee, repair fee, towing fee, cleaning fee, admin fee etc). If the HIRER
      contravenes any of the terms and conditions herein, the COMPANY has the right to utilize the whole or part
      of the deposit as payment for the loss incurred by the COMPANY.
    </li>
    <li>
      租用人需根據合約內容按時繳交車輛租金。如租用人於到期還款日仍未繳交有關租金，黃蜂租車有權向租用人收取「逾期費用」或 執行強制收車而不另行通知。「逾期費用」計算方式為每日[合約租金x1%]，直至完成繳款為止。
      <br>
      The HIRER shall settle the vehicle rental on time under the rental contracts. If a HIRER has yet settled the
      rental fee after the due date, WASP is entitled to charge the HIRER a “Late Charge” or to retrieve the car
      without any notice. “Late Charge” is calculated on a daily basis [1% of the rental fee] until settlement.
    </li>
    <li>
      租用人必需按租車合約上列明之租用限期內把車交還本公司，如租用人需延長租期，租用人可透過應用程式 [黃蜂租車]
      選擇續約。否則本公司在租車期滿後有權取回出租車輛而不作另行通知。本公司因此蒙受之損失如拖車費，賠償費等均由租用人負責 支付及承擔。
      <br>
      The HIRER must return the car to the COMPANY within the lease period specified in the lease contract. If the
      HIRER needs to extend the lease period, the HIRER can choose to renew the contract through the APP "WASP
      HK". Otherwise, the COMPANY has the right to retrieve the rental vehicle without prior notice after the
      rental period expires. The loss suffered by the COMPANY, such as towing fees, compensation fees, etc., shall
      be paid and borne by the HIRER.
    </li>
    <li>
      租用期內，車輛如有任何故障，租用人必須立刻通知本公司或本公司指定之拖車公司將車拖往維修及檢驗。若故障起因是由租用人引起緊急援助，一切有關之費用將由租用人負責，本公司亦不會安排另一車輛給租用人使用。
      <br>
      In case of an emergency breakdown, the HIRER must notify the COMPANY or the appointed service agent
      immediately to tow back the Vehicle for repair and inspection. If any emergency rescue was caused by HIRER,
      The HIRER should be responsible for all related chargers and no replacement car will be provided to HIRER.
    </li>
    <li>
      在租車期內發生交通意外，租用人必須立刻報警，並即時透過應用程式 [緊急支援] 報告意外詳情。再者，租用人必須紀錄所有當事人及目擊證人之姓名，地址等資料，隨即按實情詳列在應用程式 [緊急支援]
      內，否則一切責任，均由租用人全部承擔。
      <br>
      If a traffic accident occurs during the rental period, the HIRER must call the police immediately and report
      the details of the accident through the APP "Emergency Support". Furthermore, the HIRER must record the
      names and addresses of all parties and witnesses, and then list them in the APP "Emergency Support" in
      detail according to the facts, otherwise, all responsibilities shall be borne by the HIRER.
    </li>
    <li>
      出租車輛發生意外而導致損壞，租用人若未經本公司同意，當下不建議承認任何責任亦不得擅自進行修理。凡有關出租車輛生意所引起之第三者追討，保險索償及訴訟等，租用人必須知會本公司，並與本公司充分合作，否則一切責任，均有租用人承擔。
      <br>
      In the event of an accident resulting in damage to hired Vehicle, The HIRER shall not without the prior
      consent of the COMPANY give any instructions for repair to the Vehicle or for the replacement of any parts
      thereof rendered necessary by the accident. The HIRER shall not admit any liability nor settle or compromise
      any claim arising as a result of the accident unless with the prior consent of the COMPANY. Any claim
      against the other party, insurance claim, or legal procedure involving the hired Vehicle should notify the
      COMPANY and full cooperation is needed from the HIRER. Failure to do so will result in the HIRER being
      personally liable.
    </li>
    <li>
      當租用人不幸遇上由第三方引起的交通意外而導致車輛受到損毀，租用人只需要承擔有關保險墊底費用（港幣兩萬元) 及 預先繳付有關車輛維修費用。當責任釐清為第三方，有關保險墊底費用（港幣兩萬元)
      及車輛維修費用將會退回；但如果意外責任由租用人引起，租用人除了需要承擔保險墊底費（港幣兩萬元)，亦需要承擔其他衍生的費用，例如車輛維修期間的租金及維修費用等，對於擁有第三者責任保險的車輛，有關費用最高自負額為（港幣八萬元)，固交通意外總自負額最高為（港幣十萬元)；對於擁有綜合汽車保險的車輛，有關其他衍生的費用最高自負額為（港幣兩萬元)，固交通意外總自負額最高為（港幣四萬元)。
      <br>
      When a HIRER, unfortunately, encounters a traffic accident arising from a third party(ies) and causing damage to
      the vehicle, the HIRER will only be responsible for the deductible of (HK$20,000) and the prepayment of the repair
      fee for the damaged vehicle. After clarifying the third-party liability, the relevant deductible expenses and the
      repair fee will be refunded. Should the responsibility for the traffic accident arise from the HIRER, the HIRER
      will be responsible for, apart from the deductible (HK$20,000), other expenses, including, without limitation, the
      rental fee during repair, the repair fee and so on. For vehicles with third-party insurance coverage, the maximum
      deductible amount for other expenses is (HK$80,000), which the total maximum deductible amount for a traffic
      accident is (HK$100,000); For vehicles with comprehensive insurance coverage, the maximum deductible amount for
      other expenses is (HK$20,000), which the total maximum deductible amount for a traffic accident is (HK$40,000).
    </li>
    <li>
      租用的車輛不得用於任何違法目的，包括任何賽事或競賽等等。如本公司發現租用人在使用汽車時違反合約條款或用於違法目的，我們有權單方面提早終止合約或執行強制收車。而有關責任一切由租用人承擔。
      <br>
      The HIRER shall not use the Vehicle for hire, driving tuition, competing in any rally or for any illegal
      purpose whatsoever. There HIRER shall not affect any alternation or the body, accessories or engine of the
      Vehicle nor do anything to alter the appearance thereof. So long as the HIRER and the nominated drivers are
      not in breach of this Agreement or be enforced the vehicles return and the Hong Kong Road Traffic Ordinance,
      the HIRER’s liability for damage or loss to the Vehicle will be limited to the respective liability cap
      stated in this Agreement.
    </li>
    <li>
      本公司可定期修訂這些使用條款，恕不作另行通知。而條款及細則的解釋由本公司全權決定。如有任何爭議，本公司保留一切最終決定權。如有疑問，請向我們客戶服務查詢。
      <br>
      WASP HK LIMITED may revise these terms of use regularly without notice. The interpretation of the terms and
      conditions is determined solely by WASP HK LIMITED. In case of any dispute(s), WASP HK LIMITED reserves the
      right of final decision. If you have any questions, please contact our Customer Service.
    </li>
    <li>
      中文譯本僅供參考，文義如與 英文有歧異，概以英文本為準。
      <br>
      The Chinese translation is for reference only. In case of any discrepancy, the English version shall
      prevail.
    </li>
  </ol>


</div>