<div class="ion-padding">
  黃蜂集團香港有限公司 (下稱「黃蜂集團」) 承諾確保閣下的私隱受到保護。如果我們要求閣下提供某些資料，讓閣下在使用本應用程式給我們識別到閣下的身分，則閣下可被確保有關資料只會按照此私隱政策予以運用。
  <br>
  我們會收集下列個人資料：
  <br>
  閣下性別、中英文姓名、香港身份證號碼、駕駛執照號碼、出生日期、聯絡資料 (包括香港手提電話號碼、電郵地址、住址)、付款資料 (包括但不限於信用卡、扣帳卡和其他網上銀行資料。
  <br><br>
  我們會如何處理收集到的資料：
  <br>
  黃蜂集團要求租用人所提供的個人資料，只用作聯絡租用人及核實身份之用，並會妥善保存及保密處理已收集之個人資料。如租用人拒絕提供有關資料，黃蜂集團有權拒絕向租用人提供租賃服務。
  <br><br>
  安全：
  <br>
  我們為確保閣下的資料安全，並為避免未獲授權的存取或披露，已採取適當程序，以保障及保護我們在網上收集到的資料。
  <br><br>
  連接其他網站的連結：
  <br>
  我們的應用程式可能會包含連接其他網站或應用程式的連結。不過，當閣下運用這些連結離開我們的應用程式，我們概不能控制其他網站。故此，如果閣下在瀏覽那些網站或應用程式時提供任何資料，我們概不負責該等資料的保護及私隱事宜，而該等網站或應用程式亦不受本私隱政策所監管。閣下應審慎行事，並細閱適用於有關網站及應用程式的私隱聲明。
  <br><br>
  監控閣下的個人資料：
  <br>
  我們不會將閣下的個人資料銷售、租賃或分送予第三方(除非法例有此規定)。如果閣下相信我們所持有閣下的資料不正確或不完整，請盡快電郵予我們。我們會盡快將有關資料作相應更新。
</div>