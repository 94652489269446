import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { IonicSlides, ModalController } from '@ionic/angular';
import Swiper from 'swiper';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
})
export class GalleryComponent  implements OnInit {
  swiperModules = [IonicSlides];
  @ViewChild('swiper') swiperRef: ElementRef | undefined;
  swiper?: Swiper;

  @Input() img_url_list: string[] = null;
  @Input() current_index: number = 0;

  constructor(
    public modalController: ModalController
  ) { }

  ngOnInit() {
  }

  swiperReady() {
    setTimeout(() => {
      this.swiper = this.swiperRef?.nativeElement.swiper;
      if (this.swiper && this.current_index != null){
        this.swiper.slideTo(this.current_index);
      }
      this.swiper.update();
      this.swiper.updateAutoHeight(500);
    }, 100);
  }

  slideTo(index){
    this.swiper.slideTo(index);
  }
  
  slidePrev(){
    this.swiper.slidePrev();
  }
  
  slideNext(){
    this.swiper.slideNext();
  }

  onSlideChange(ev?) {
    this.swiper.update();
    this.swiper.updateAutoHeight(500);
  }

}
