<ion-card class="parking-card"
  (click)="commonService?.openGoogleMap(parking_data.latitude, parking_data.longitude, parking_data.zh_address)">
  <!-- <img [src]="('media_url' | env)+parking_data?.cover_img_url" alt=""> -->
  <div class="img-div"
    [ngStyle]="{'background': 'url('+('media_url' | env)+parking_data?.cover_img_url+') no-repeat center center / cover'}">
    <!-- background: url(/assets/image/plan-banner.png) no-repeat center
      center / cover; -->
    <div class="not-rentable-cover" *ngIf="parking_data?.is_private">
      <h6 class="ion-text-center">{{'common.private_parking'| translate}}</h6>
    </div>
    <div class="not-rentable-cover" *ngIf="parking_data?.is_coming_soon">
      <h6 class="ion-text-center">{{'common.coming_soon'| translate}}</h6>
    </div>
  </div>
  <ion-card-header>
    <ion-chip *ngIf="parking_data?.is_private" mode="ios" outline="false">
      <ion-label>{{'common.private_parking'| translate}}</ion-label>
    </ion-chip>
    <ion-chip *ngIf="parking_data?.is_car_rental_point == true" mode="ios" outline="false">
      <ion-label>{{'common.rental_point'| translate}}</ion-label>
    </ion-chip>
    <ion-card-title>
       <!--<ion-row>
        <ion-col size="6">{{translateService.currentLang == 'zh' ? parking_data?.zh_name :
          parking_data?.en_name}}</ion-col>
        <ion-col size="6" class="ion-text-right">
          剩餘車位
        </ion-col>
      </ion-row> -->
        {{translateService.currentLang == 'zh' ? parking_data?.zh_name :
        parking_data?.en_name}}
    </ion-card-title>
  </ion-card-header>
  <ion-card-content>


    <ion-row>
      <ion-col size="8">
        <div class="address">
          <ion-icon name="location"></ion-icon>
          <p>{{translateService.currentLang == 'zh' ? parking_data?.zh_address : parking_data?.en_address}}</p>
        </div>

      </ion-col>
      <ion-col size="4" class="ion-text-center">
        <ion-text>
          <h3 class="ion-text-center">{{'common.vacancy'| translate}}</h3>
        </ion-text>
        <ion-text color="primary" *ngIf="parking_data?.vacancy !== undefined">
          <h1 class="ion-text-center" >{{(parking_data?.vacancy ) || "/"}}</h1>
        </ion-text>
        <ion-spinner color="primary" *ngIf="parking_data?.vacancy === undefined" name="crescent"></ion-spinner>
        <p class="ion-text-center" style="font-size: 10px;">{{'common.last_updated_1_minute_ago' | translate}}</p>
      </ion-col>
    </ion-row>



  </ion-card-content>
</ion-card>