import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/services/common.service';
import { ParkingData } from 'src/schema';

@Component({
  selector: 'app-parking-card',
  templateUrl: './parking-card.component.html',
  styleUrls: ['./parking-card.component.scss'],
})
export class ParkingCardComponent  implements OnInit {
  @Input() parking_data: ParkingData | any = null;

  constructor(
    public translateService: TranslateService,
    public commonService: CommonService
  ) { }

  ngOnInit() {}

}
