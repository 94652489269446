import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-start-end-panel',
  templateUrl: './start-end-panel.component.html',
  styleUrls: ['./start-end-panel.component.scss'],
})
export class StartEndPanelComponent implements OnInit {
  @Input() start_date: string = '';
  @Input() end_date: string = '';
  @Input() style: number = null;
  @Input() show_year: boolean = null;
  
  today = this.commonService.ToBackendDateTimeString(new Date()).split('T')[0];
  constructor(
    public navigationService: NavigationService,
    public commonService: CommonService
  ) { }

  ngOnInit() {
    // if (this.show_year == null && this.start_date.split('-')[0] != this.end_date.split('-')[0]){
    //   this.show_year = true;
    // }
  }

}
