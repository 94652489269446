<ion-row class="item-row">
    <ion-col size="12" size-md="3.5">
        <img src="assets/image/home-influence-img1.png" alt="">
    </ion-col>
    <ion-col size="12" offset-lg="0.5" size-md="8" class="ion-align-self-center">
        <h2 [innerHTML]="'influence_item.title' | translate | safe">攜⼿<span style='color: var(--ion-color-primary);'>減低</span>城市碳排放</h2>
        <p>{{'influence_item.desc' | translate}}</p>
        <ion-button class="default-btn" shape="round" (click)="navigationService.navigate('root', 'cars')">
            {{'common.our_cars' | translate}}
        </ion-button>
    </ion-col>
</ion-row>
<ion-row class="item-row">
    <ion-col size="12" size-md="3.5">
        <img src="assets/image/home-influence-img2.png" alt="">
    </ion-col>
    <ion-col size="12" offset-lg="0.5" size-md="8" class="ion-align-self-center">
        <h2 [innerHTML]="'influence_item.title2' | translate | safe"><span>多區</span>租⾞據點</h2>
        <p>{{'influence_item.desc2' | translate}}</p>
        <ion-button class="default-btn" shape="round" (click)="navigationService.navigate('root', 'rental-point')">
            <ion-icon slot="start" name="location"></ion-icon>
            {{'common.car_parking_location' | translate}}
        </ion-button>
    </ion-col>
</ion-row>
<ion-row class="item-row">
    <ion-col size="12" size-md="3.5">
        <img src="assets/image/home-influence-img3.png" alt="">
    </ion-col>
    <ion-col size="12" offset-lg="0.5" size-md="8" class="ion-align-self-center">
        <h2 [innerHTML]="'influence_item.title3' | translate | safe"><span>減少</span>交通擠塞</h2>
        <p>{{'influence_item.desc3' | translate}}</p>
        <ion-button class="default-btn" shape="round" (click)="navigationService.navigate('root', 'our-influence')">
            {{'common.sustainable' | translate}}
        </ion-button>
    </ion-col>
</ion-row>
<ion-row class="item-row">
    <ion-col size="12" size-md="3.5">
        <img src="assets/image/home-influence-img4.png" alt="">
    </ion-col>
    <ion-col size="12" offset-lg="0.5" size-md="8" class="ion-align-self-center">
        <h2 [innerHTML]="'influence_item.title4' | translate | safe">弱勢社群<span>就業</span>機會</h2>
        <p [innerHTML]="'influence_item.desc4' | translate | safe">
            我們致力與社會服務機構合作，招募和培訓弱勢社群成員，他們負責租車據點的清潔和車輛的維護工作。這些成員包括一些身體殘障、智力障礙或長期失業的人士。
            <br>
            我們相信這樣的模式將締造和諧共融的工作環境，並影響更多企業和社會組織，無論員工或顧客都能夠從中得益。
        </p>
        <ion-button (click)="navigationService.navigate('root', 'opportunities-of-the-underprivileged')" class="default-btn" shape="round">
            {{'common.know_more' | translate}}
        </ion-button>
    </ion-col>
</ion-row>