import { Directive, ElementRef, HostListener } from '@angular/core';
// import { AnimationBuilder } from '@ionic/angular';

@Directive({
  selector: '[appAnimate]'
})
export class AnimateDirective {
  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll() {
    console.log(123);
    this.animate();
  }

  // private animating: boolean;
  // private player: AnimationPlayer;
  private defaults: any = {
    offset: 0,
  };

  constructor(
    private el: ElementRef,
    // private animationBuilder: AnimationBuilder
  ) {}

  ngOnInit() {
    // this.initialize();
    this.animate();
  }

  // private initialize(): void {
  //   let animation: AnimationFactory;

  //   if (
  //     this.animateInAnimation !== null &&
  //     this.animateInAnimation !== undefined
  //   ) {
  //     animation = this.animationBuilder.build(this.animateInAnimation);
  //   } else {
  //     animation = this.animationBuilder.build([
  //       style({ opacity: 0, transform: 'translateX(-100px)' }),
  //       animate(
  //         '1200ms cubic-bezier(0.35, 0, 0.25, 1)',
  //         style({ opacity: 1, transform: 'translateX(0)' })
  //       ),
  //     ]);
  //   }

  //   this.player = animation.create(this.el.nativeElement);
  //   this.player.init();
  // }

  private animate(): void {
    const inView = this.isInViewport();
    console.log(inView);
    if (inView) {
      console.log("InView: ",this.el);
      //add animation class
    }

    // if (!inView) this.animating = false;
    // if (!inView || this.animating) return;

    // this.player.play();
    // this.animating = true;
  }

  private isInViewport(): boolean {
    const bounding = this.el.nativeElement.getBoundingClientRect();

    let top =
      bounding.top -
      (window.innerHeight || document.documentElement.clientHeight);
    let bottom = bounding.top + bounding.height + this.defaults.offset;

    return top < 0 && bottom > 0;
  }

}
