import { Injectable } from '@angular/core';
// import { Storage } from '@ionic/storage';
import { TranslateService } from '@ngx-translate/core';
import { LOCAL_STORAGE_KEY } from './common.service';
// import { STORAGE_KEY, StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor(
    private translateService: TranslateService,
    // private storageService: StorageService,
  ) { 
  }

  initLang(){

    
    let language_key = localStorage.getItem(LOCAL_STORAGE_KEY.LANGUAGE_KEY);
    if (language_key != undefined && language_key != null){
      // console.log(language_key);
      this.translateService.setDefaultLang(language_key);
      this.setLanguage(language_key)
    }
    else{
      this.translateService.setDefaultLang('zh');
      this.setLanguage('zh');
    }

    // this.storageService.get(STORAGE_KEY.SELECTED_LANGUAGE).then(val => {
    //   if (val != undefined && val != null) {
    //     this.setLanguage(val); 
    //   }
    //   else{
    //     this.translateService.setDefaultLang('zh'); // add this
    //     this.setLanguage('zh');
    //   }
    // }); 


  }

  setLanguage(lang){
    this.translateService.use(lang);
    localStorage.setItem(LOCAL_STORAGE_KEY.LANGUAGE_KEY, lang)
  }

  switchLanguage(){
    this.setLanguage(this.translateService.currentLang == 'en' ? 'zh' : 'en');

  }

}
