<section class="rtb-faq">
  <h1 class="ion-text-center">{{'common.frequently_asked_questions' | translate}}</h1>
  <ion-accordion-group>
    <ion-accordion [value]="i" *ngFor="let item of faq_data?.detail_list; let i = index">
      <ion-item slot="header">
        <ion-label>{{translateService.currentLang == 'zh' ? item?.zh_title : (item?.en_title != '' ? item?.en_title :
          item?.zh_title)}}</ion-label>
      </ion-item>
      <div class="ion-padding" slot="content">{{translateService.currentLang == 'zh' ? item?.zh_content :
        (item?.en_content != '' ? item?.en_content : item?.zh_content)}}</div>
    </ion-accordion>
  </ion-accordion-group>
  <div class="ion-text-center">
    <div class="ion-text-center ion-margin-top" *ngIf="navigationService.current_router_url.includes('rent-with-park')">
      <ion-button class="default-btn" shape="round" download="GoSwap_T&C.pdf" [href]="'/assets/pdf/GoSwap_T&C.pdf'+'?'+time">
        {{'common.terms_n_conditions' | translate}}
      </ion-button>&nbsp; &nbsp; 
      <ion-button class="default-btn" shape="round" download="GoSwap_Free_Parking-tnc.pdf"
        [href]="'/assets/pdf/GoSwap_Free_Parking-tnc.pdf'+'?'+time">
        {{'common.free_parking_terms_n_conditions' | translate}}
      </ion-button>
    </div>
    <p>{{'common.more_questions' | translate}}
      <a href="https://api.whatsapp.com/send?phone=85236117685" target="_blank"
        rel="noopener noreferrer">{{'common.contact_us' | translate}}</a>
    </p>
  </div>
</section>