<!-- <ion-card class="parking-card" (click)="commonService?.openGoogleMap(parking_data.latitude, parking_data.longitude, parking_data.zh_address)"> -->
<ion-card class="parking-card" >
  <div class="img-div" [ngStyle]="{'background': 'url('+('media_url' | env)+factory_data?.cover_img_url_list[0]+') no-repeat center center / cover'}">
    <!-- background: url(/assets/image/plan-banner.png) no-repeat center
      center / cover; -->
  </div>
  <ion-card-header>
    <ion-card-title>{{translateService.currentLang == 'zh' ? factory_data?.zh_name : factory_data?.en_name}}</ion-card-title>
  </ion-card-header>
  <ion-card-content>

    <div class="address">
      <ion-icon name="location"></ion-icon>
      <p>{{translateService.currentLang == 'zh' ?  factory_data?.zh_address : factory_data?.en_address}}</p>
    </div>
  </ion-card-content>
</ion-card>