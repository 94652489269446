<div class="esg">
  <img style="max-width: 540px;" class="mobile-hide" src="/assets/image/esg.png">
  <img class="desktop-hide " src="/assets/image/esg-mobile.png">
</div>
<footer>
  <ion-row>
    <!-- <ion-col size="12" class="esg ion-text-center">
      <img class="mobile-hide " src="/assets/image/esg.png">
      <img class="desktop-hide " src="/assets/image/esg-mobile.png">
    </ion-col> -->
    <ion-col size="12" size-md="2" class="category">
      <h4 (click)="navigationService.navigate('root', 'plan')">{{'footer.service_plan' | translate}}</h4>
      <p (click)="navigationService.navigate('root', 'rent-with-park')">{{'common.rent_with_park' | translate}}</p>
      <p (click)="navigationService.navigate('root', 'rent-to-buy')">{{'common.rent_to_buy' | translate}}</p>
      <p (click)="navigationService.navigate('root', 'long-term-rental')">{{'common.long_term_rental' | translate}}</p>
      <!-- <p (click)="navigationService.navigate('root', 'six-plus-one')">6+1</p> -->
    </ion-col>
    <!-- <ion-col size="12" size-md="2" class="category">
      <h4 class="empty">&nbsp;</h4>
      <p>{{'common.free_charging' | translate}}</p>
      <p class="empty">&nbsp;</p>
      <p class="empty">&nbsp;</p>
    </ion-col> -->
    <ion-col size="12" size-md="2" class="category">
      <h4>{{'footer.explore_more' | translate}}</h4>
      <p  (click)="navigationService.navigate('root', 'our-team')">{{'footer.meet_our_team' | translate}}</p>
      <p (click)="navigationService.navigate('root', 'our-influence')">{{'footer.sustainable_development' | translate}}</p>
      <p (click)="navigationService.navigate('root', 'opportunities-of-the-underprivileged')">{{'footer.job_opportunities' | translate}}</p>
      <p (click)="navigationService.navigate('root', 'press')">{{'footer.press' | translate}}</p>
    </ion-col>
    <ion-col size="12" size-md="2" class="category">
      <h4>{{'footer.group_members' | translate}}</h4>
      <a href="https://parkingbees.com.hk/" target="_blank" rel="noopener noreferrer">
        <p>AI Parking Bees</p>
      </a>
      <a href="https://wasphk.com/" target="_blank" rel="noopener noreferrer">
        <p>WASP HK</p>
      </a>
    </ion-col>
    <ion-col size="12" size-md="2" class="category">
      <h4>{{'footer.contact_us' | translate}}</h4>
      <a href="https://maps.app.goo.gl/hjznKc6s4PsnJeAo8" target="_blank" rel="noopener noreferrer">
        <p>觀塘成業街27號日昇中心405室</p>
      </a>
      <a href="tel:+85236117691">
        <p>3611 7685</p>
      </a>
      <a href="mailto:hello@wasphk.com">
        <p>hello@wasphk.com</p>
      </a>
    </ion-col>
    <ion-col size="12" size-md="4" class="logo">
      <a href="/"> <img class="ion-margin-top ion-margin-bottom goswap-logo"
          [src]="commonService?.is_dark_mode ? 'assets/icon/header-goswap-dark.png' : 'assets/icon/header-goswap-light.png'"
          alt=""></a>
      <br>

      <div>
        <a href="https://apps.apple.com/hk/app/goswap-%E9%BB%83%E8%9C%82%E7%A7%9F%E8%BB%8A/id1556833574" target="_blank" rel="noopener noreferrer">
          <img src="assets/image/appstore.svg" alt="">
        </a>
        &nbsp;
        <a href="https://play.google.com/store/apps/details?id=com.wasphk.client&hl=zh_HK&gl=US" target="_blank" rel="noopener noreferrer">
          <img src="assets/image/google-play.svg" alt="">
        </a>
      </div>
    </ion-col>
    <ion-col size="12">
      <div class="divider"></div>
      <p class="copyright ion-text-center">Copyright @2023 GoSwap. All rights reserved. (v{{'appVersion' | env}})</p>
    </ion-col>

  </ion-row>
</footer>