import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { Destroy } from './directives/destroy';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import localeHk from '@angular/common/locales/zh-Hant-HK';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { GoogleMapsModule } from '@angular/google-maps';
import { SharedModule } from './shared/shared.module';
import { CommonModule, registerLocaleData } from '@angular/common';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from 'src/environments/environment';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/locale/', '.json?v=' + Date.now());
}
export function playerFactory() {
  return player;
}

registerLocaleData(localeHk, 'zh-HK');

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule, 
    IonicModule.forRoot({
      // mode: "ios"
    }), 
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    LottieModule.forRoot({ player: playerFactory }),
    GoogleMapsModule,
    SharedModule,
    NgxStripeModule.forRoot(environment.stripe_publishable_key),
    CommonModule
  ],
  providers: [{ 
    provide: 
    RouteReuseStrategy, 
    useClass: 
    IonicRouteStrategy ,
  },
  { provide: LOCALE_ID, useValue: 'zh-HK'},
  Destroy
],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
