<ion-row class="date-range-container" *ngIf="style == 1">
  <ion-col size="6" class="ion-text-center">
    <div class="start-date">
      <h5 *ngIf="navigationService?.current_router_url.includes('booking-step1')">開始</h5>
      <h5 *ngIf="start_date == null || start_date == ''">選擇開始日期</h5>
      <h5 *ngIf="today == start_date">今天</h5>
      <h5 class="date" *ngIf="start_date != null && start_date != ''">{{start_date | date : 'mediumDate'}}</h5>
    </div>
  </ion-col>
  <ion-col size="6" class="ion-text-center">
    <div class="end-date">
      <h5 *ngIf="navigationService?.current_router_url.includes('booking-step1')">結束</h5>
      <h5 *ngIf="end_date == null || end_date == ''">選擇結束日期</h5>
      <h5 class="date" *ngIf="end_date != null && end_date != ''">{{end_date | date : 'mediumDate'}}</h5>
    </div>
  </ion-col>
  <ion-icon name="arrow-forward-outline"></ion-icon>
</ion-row>

<ion-row class="date-range-container2" *ngIf="style == 2">
  <ion-col size="5">
    <strong>
      <ion-label>開始日期</ion-label>
    </strong>
    <h3>
      <span *ngIf="start_date?.substring(0, 4) != end_date?.substring(0, 4)">{{start_date | date : 'yyyy'}} <br></span>
      {{start_date | date : 'MMM d'}}</h3>
    <strong>
      <ion-label>{{start_date | date : 'EEE'}}</ion-label>
    </strong>
  </ion-col>
  <ion-col size="2" class="ion-align-self-center ion-text-center">
    <ion-icon name="arrow-forward-circle-outline"></ion-icon>
  </ion-col>
  <ion-col size="5">
    <strong>
      <ion-label>結束日期</ion-label>
    </strong>
    <h3>
      <span *ngIf="start_date?.substring(0, 4) != end_date?.substring(0, 4)">{{end_date | date : 'yyyy'}} <br></span>
      {{end_date | date : 'MMM d'}}</h3>
    <strong>
      <ion-label>{{end_date | date : 'EEE'}}</ion-label>
    </strong>
  </ion-col>
</ion-row>

<ion-row class="date-range-container3" *ngIf="style == 3">
  <ion-col size="6">
    <strong>
      <ion-label>開始日期</ion-label>
    </strong>
    <h3>
      <span *ngIf="start_date?.substring(0, 4) != end_date?.substring(0, 4)">{{start_date | date : 'yyyy'}} <br></span>
      {{start_date | date : 'MMM d'}}</h3>
    <strong>
      <ion-label>{{start_date | date : 'EEE'}}</ion-label>
    </strong>
  </ion-col>
  <ion-col size="6">
    <strong>
      <ion-label>結束日期</ion-label>
    </strong>
    <h3>
      <span *ngIf="start_date?.substring(0, 4) != end_date?.substring(0, 4)">{{end_date | date : 'yyyy'}} <br></span>
      {{end_date | date : 'MMM d'}}</h3>
    <strong>
      <ion-label>{{end_date | date : 'EEE'}}</ion-label>
    </strong>
  </ion-col>
</ion-row>

<ion-row class="date-range-container4" *ngIf="style == 4">
  <ion-col size="5">
    <strong>
      <ion-label>開始日期</ion-label>
    </strong>
    <h3>
      <span *ngIf="start_date?.substring(0, 4) != end_date?.substring(0, 4)">{{start_date | date : 'yyyy'}} <br></span>
      {{start_date | date : 'MMM d'}}
    </h3>
    <strong>
      <ion-label>{{start_date | date : 'EEE'}}</ion-label>
    </strong>
  </ion-col>
  <ion-col size="2" class="ion-align-self-center ion-text-center">
    <ion-icon name="arrow-forward-circle-outline"></ion-icon>
  </ion-col>
  <ion-col size="5" class="ion-text-right">
    <strong>
      <ion-label>結束日期</ion-label>
    </strong>
    <h3>
      <span *ngIf="start_date?.substring(0, 4) != end_date?.substring(0, 4)">{{end_date | date : 'yyyy'}} <br></span>
      {{end_date | date : 'MMM d'}}
    </h3>
    <strong>
      <ion-label>{{end_date | date : 'EEE'}}</ion-label>
    </strong>
  </ion-col>
</ion-row>