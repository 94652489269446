import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Response } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { FaqData, FaqDataDetail } from 'src/schema';

@Component({
  selector: 'app-faq-section',
  templateUrl: './faq-section.component.html',
  styleUrls: ['./faq-section.component.scss'],
})
export class FaqSectionComponent implements OnInit {
  @Input() faqId = null;
  faq_data: FaqData = null;

  time = new Date().getTime();

  constructor(
    private dataService: DataService,
    public navigationService: NavigationService,
    public translateService: TranslateService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    this.ngOnInit();
  }

  async ngOnInit() {
    // console.log(this.faqId);
    const get_data: Response = await this.dataService.getAllFaqDataList();
    if (get_data.result == 'success') {
      if (this.faqId == null) {
        if (this.navigationService.current_router_url.includes('rent-to-buy')) {
          this.faq_data = get_data.data.find((d: FaqData) => d.zh_category.includes('先租後買'));
        }
        else if (this.navigationService.current_router_url.includes('rent-with-park')) {
          this.faq_data = get_data.data.find((d: FaqData) => d.zh_category.includes('租車連車位'));
        }
        else if (this.navigationService.current_router_url.includes('tesla')) {
          this.faq_data = get_data.data.find((d: FaqData) => d.zh_category.includes('Tesla'));
        }
      }
      else {
        this.faq_data = get_data.data.find((d: FaqData) => d.id == this.faqId);
      }

    }
    // console.log(this.faq_data);
  }


}
