import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-plan-item',
  templateUrl: './plan-item.component.html',
  styleUrls: ['./plan-item.component.scss'],
})
export class PlanItemComponent  implements OnInit {
  constructor(
    public navigationService: NavigationService
  ) { }

  ngOnInit() {}

}
