import { Component, Input, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { CarData } from 'src/schema';

@Component({
  selector: 'app-car-item',
  templateUrl: './car-item.component.html',
  styleUrls: ['./car-item.component.scss'],
})
export class CarItemComponent implements OnInit {
  @Input() car_data: CarData | null | any = null;
  @Input() rtb_desktop_style: boolean = false;

  constructor(
    public navigationService: NavigationService,
    public dataService: DataService
  ) { }

  ngOnInit() { }

  selectCar(car_id: number) {
    if (this.navigationService.current_router_url.includes('rent-to-buy')) return;
    if (this.navigationService.current_router_url.includes('prerent')){
      this.dataService.selected_prerent_car_data = this.car_data;
      return;
    }
    this.navigationService.navigate('root', 'car-detail?id=' + car_id)
  }

}
