<div class="container">
  <div class="close-logo">
    <ion-icon name="close" (click)="commonService.show_mobile_download_footer = false"></ion-icon>
    <img src="assets/icon/goswap_logo_only.svg" alt="">
    <div>
      <strong>
        <p class="ion-no-margin" [innerHTML]="'mobile_download_app.title' | translate">打開GoSwap
          <br>
          即刻落單租車！
        </p>
      </strong>
      <img src="assets/image/download-app-stars.svg" alt="">
    </div>
  </div>

  <ion-button color="primary" shape="round" fill="solid" size="medium" target="_blank" href="http://onelink.to/a9yn2n">
    {{'common.download' | translate}}
  </ion-button>
</div>