import { Injectable } from '@angular/core';
import { LoadingController, ModalController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { GalleryComponent } from '../components/gallery/gallery.component';

export enum LOCAL_STORAGE_KEY {
  DARK_MODE = 'DARK_MODE',
  LANGUAGE_KEY = 'LANGUAGE_KEY'
};
@Injectable({
  providedIn: 'root'
})
export class CommonService {

  public load_count: number = 0;
  public is_dark_mode: boolean = null;
  public show_mobile_download_footer: boolean = true;

  constructor(
    private toastController: ToastController,
    private loadingCtrl: LoadingController,
    private translateService: TranslateService,
    private modalCtrl: ModalController
  ) {

  }

  async presentToast(msg, time?, position?: 'top' | 'bottom') {
    const toast = await this.toastController.create({
      message: msg,
      duration: (time == undefined || time == null ? 2500 : time),
      position: (position == undefined || position == null ? 'bottom' : position),
      cssClass: 'default-toast'
    });

    await toast.present();
  }

  async presentErrorToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 4000,
      position: 'top'
    });

    await toast.present();
  }

  async presentSystemErrorToast() {
    const toast = await this.toastController.create({
      message: this.translateService.currentLang == 'en' ? 'System Error' : '系統錯誤',
      duration: 3000,
      position: 'top'
    });

    await toast.present();
  }

  ToBackendDateTimeString(date: Date) {
    return (new Date(date.getTime() - (new Date()).getTimezoneOffset() * 60000)).toISOString().slice(0, -1).split(".")[0];
  }

  async presentLoading(time?: number, msg?: string) {

    if (this.load_count == 0) {
      this.load_count++;
      return await this.loadingCtrl.create({
        message: msg && msg != '' ? msg : `${this.translateService.currentLang == 'en' ? 'Please wait...' : '請稍候...'}`,
        mode: "ios",
        spinner: 'crescent',
        cssClass: 'LoadingCss',
        duration: (time != undefined && time != null ? time : 30000), //30s
      }).then(a => {
        a.present().then(() => {
          if (this.load_count <= 0) {
            this.load_count = 0;
            a.dismiss();
          }
        });
      });
    } else {
      this.load_count++;
    }
  }

  async dismissLoading() {
    this.load_count--;
    if (this.load_count <= 0) {
      this.load_count = 0;
      return await this.loadingCtrl.dismiss().catch(err => console.error(err));;
    }
  }

  getDateDiffInDays(a: Date, b: Date) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }

  generateAllDateBetweenTwoDate(start_date, end_date): Promise<string[]> {
    return new Promise((resolve, reject) => {
      let booking_date_list = [];
      let day = 1000 * 60 * 60 * 24;
      let diff = (new Date(end_date).getTime() - new Date(start_date).getTime()) / day;
      // console.log(diff);
      for (let i = 0; i < diff; i++) {
        let xx = new Date(start_date).getTime() + day * i;
        let yy = new Date(xx);
        let date = yy.getFullYear() + "-" + ('0' + (yy.getMonth() + 1)).slice(-2) + "-" + ('0' + (yy.getDate())).slice(-2);
        booking_date_list.push(date);
      }
      resolve(booking_date_list);
    });
  }

  addDaysToDate(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  // async openGalleryModal(img_url_list: string[], shareable: boolean) {
  //   if (img_url_list == null || img_url_list.length <= 0) return;
  //   const modal = await this.modalCtrl.create({
  //     component: GalleryComponent,
  //     componentProps: {img_url_list: img_url_list, shareable: shareable}
  //   });
  //   modal.present();

  //   const { data, role } = await modal.onWillDismiss();

  //   // if (role === 'confirm') {
  //   //   this.message = `Hello, ${data}!`;
  //   // }
  // }

  shallowComparisonOfObject(obj1, obj2) {
    return Object.keys(obj1).length === Object.keys(obj2).length &&
      (Object.keys(obj1) as (keyof typeof obj1)[]).every((key) => {
        return (
          Object.prototype.hasOwnProperty.call(obj2, key) && obj1[key] === obj2[key]
        );
      });
  }

  // Add or remove the "dark" class on the document body
  toggleDarkTheme(shouldAdd) {
    document.body.classList.toggle('dark', shouldAdd);
    localStorage.setItem(LOCAL_STORAGE_KEY.DARK_MODE, shouldAdd)
  }

  openGoogleMap(lat, long, zh_address){
    // window.open(`https://maps.google.com/?q=${lat},${long}`, '_blank');
    window.open('https://www.google.com/maps/search/?api=1&query='+encodeURI(zh_address), '_blank');

     // console.log('https://www.google.com/maps/search/?api=1&query='+encodeURI(this.parking_data?.zh_address));
   
    // console.log(`https://www.google.com/maps/search/?api=1&query=${this.parking_data?.latitude},${this.parking_data.longitude}`);
    // this.browserService.openBrowser(`https://www.google.com/maps/search/?api=1&query=${this.parking_data?.latitude},${this.parking_data.longitude}`);
  }

  async openGalleryModal(img_url_list: string[], current_index) {
    if (img_url_list == null || img_url_list.length <= 0) return;
    const modal = await this.modalCtrl.create({
      component: GalleryComponent,
      componentProps: {img_url_list: img_url_list, current_index: current_index},
      cssClass: 'gallery-modal',
      animated: false
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    // if (role === 'confirm') {
    //   this.message = `Hello, ${data}!`;
    // }
  }

}
