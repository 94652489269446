import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/services/common.service';
import { FactoryData } from 'src/schema';

@Component({
  selector: 'app-factory-card',
  templateUrl: './factory-card.component.html',
  styleUrls: ['./factory-card.component.scss'],
})
export class FactoryCardComponent  implements OnInit {
  @Input() factory_data: FactoryData = null;

  constructor(
    public translateService: TranslateService,
    public commonService: CommonService
  ) { }

  ngOnInit() {}

}
